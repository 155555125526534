window.imoveisVisiveis = {};

window.setimoveisVisiveis = json => {
	if(imoveisVisiveis){
		imoveisVisiveis = json;
	}
};

window.moeda = input => {

	var bola = (typeof(input.length) !== 'undefined') ? input : input.value;

	var numero_temp = bola.replace(',', '');
	numero_temp = numero_temp.replace('.', '');

	let result = parseFloat(numero_temp).toLocaleString('pt-br',{minimumFractionDigits: 2});
	if(typeof(input.length) !== 'undefined'){
		return result;
	}else{
		input.value = '';
		input.value = result;
	}
};

window.activeSetaImagens = function(){
	for(var i in imoveisVisiveis){
		if(Boss.getById('arrow-right'+i)){
			Boss.getById('arrow-right'+i).classList.remove('hidden');
		}
		if(Boss.getById('arrow-left'+i)){
			Boss.getById('arrow-left'+i).classList.remove('hidden');
		}
	}
};

window.loadImages = async (imageUrlArray) => {

	const promiseArray = []; 
	const imageArray = []; 

	for (let imageUrl of imageUrlArray) {

		promiseArray.push(new Promise((resolve, error) => {

			const img = new Image();
		
			img.onload = function() {
				resolve();
			};

			img.src = imageUrl;
			imageArray.push(img);
		}));
	}

	await Promise.all(promiseArray);
	return imageArray;
};
/* Função responsável por alterar a imagem da miniatura do imóvel quando é clicado nos botões left ou right */
window.miniaturaChangeImg = function(miniatura_id, acao = 1){

	var imagemPrincipal = Boss.getById(miniatura_id);
	var imo_codigo = miniatura_id.split('-')[1];
	var btnleft = Boss.getById('btnleft-' + imo_codigo);
	var btnright = Boss.getById('btnright-' + imo_codigo);

	if(imagemPrincipal){

		var current = imoveisimagens[imo_codigo][0];
		var atual = imagemPrincipal.getAttribute('data-imgatual');

		var novaImagem = current;
		var nImagem = 0;

		/* acao = 1 === DIREITA */
		if(acao == 1){

			var maior = (Number(atual) + 1);

			if(imoveisimagens[imo_codigo][maior]){

				if((Number(imoveisimagens[imo_codigo].length) - 1) == maior){
					novaImagem = imoveisimagens[imo_codigo][maior];
					nImagem = maior;

					btnright.classList.add('hidden');
					btnleft.classList.remove('hidden');

					return false;
				}else{

					btnright.classList.remove('hidden');
					btnleft.classList.remove('hidden');
				}

				novaImagem = imoveisimagens[imo_codigo][maior];
				nImagem = maior;
			}

		}else{
		/* acao = 0 === ESQUERDA */

			var menor = (Number(atual) > 0 ? Number(atual) - 1 : 0);
		
			if(imoveisimagens[imo_codigo][menor]){
				novaImagem = imoveisimagens[imo_codigo][menor];
				nImagem = menor;

				btnright.classList.remove('hidden');
				btnleft.classList.remove('hidden');
			}

			if(menor == 0){
				btnright.classList.remove('hidden');
				btnleft.classList.add('hidden');
			}
		}

		/* Altera a data-imgatual Da IMAGEM e o SRC da mesma */
		imagemPrincipal.setAttribute('data-imgatual', nImagem);
		imagemPrincipal.src = jsdominioimg + '/imoveis/thumb/' + novaImagem;
	}
};

/* Recupera todas as imagens dos imóveis disponíveis */
window.getImovelImagens = e => {

	Boss.ajax({
		'url': '/imoveis/getImovelImagens',
		'data': {
			'getimagens': 'getimagens',
			'imoveisVisiveis': JSON.stringify(imoveisVisiveis)
		},
		'dataType': 'json',
		'done': rtn => {

			if(rtn.r == 'ok'){
				imoveisimagens = rtn.info;

				/* PRELOADER */
				for(var x in rtn.info){

					for(var y in rtn.info[x]){

						var img = document.createElement('img');
						img.src = jsdominioimg+'/imoveis/thumb/'+rtn.info[x][y];
					}
				}
			}
		},
		error: rtn => {
			Boss.warning({message: 'Falha ao enviar, tente novamente em alguns segundos!'});
		}
	});
}

window.share = function(rede, link){

	let url = 'mobile';
	if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 1100){
		url = 'pc';
	}

	if(rede == 'facebook'){

		if(url == 'mobile'){

			window.open("https://www.facebook.com/share.php?u="+link, '_blank');
		}else{

			window.open("https://www.facebook.com/share.php?u="+link, '_blank', 'width=800, height=600, toolbar=no, top=50, left=50');
		}
	}	

	if(rede == 'whatsapp'){

		if(url == 'mobile'){

			window.open("whatsapp://send?text="+link, '_blank');
		}else{

			window.open("https://web.whatsapp.com/send?text="+link, '_blank');
		}
	}

	if(rede == 'twitter'){

		if(url == 'mobile'){

			window.open("https://twitter.com/home?status="+link, '_blank');
		}else{
			window.open("https://twitter.com/home?status="+link, '_blank', 'width=800, height=600, toolbar=no, top=50, left=50');
		}
	}
};

window.imovelImprimirTipo = (imo_codigo, tipo, orientacao, completo) => {

	var pathurl = `${jsdominio}/imoveis/imprimir/?id=${imo_codigo}&t=${tipo}&o=${orientacao}&c=${completo}`;

	window.open(pathurl);
}

window.imovelImprimir = imo_codigo => {

	if(typeof(imo_codigo) == 'undefined'){
		return false;
	}

	let html = `
		<div class="boss-dialog-area">
			<h1>Imprimir Imóvel ID: ${imo_codigo}</h1>
			<p>Escolha o tipo para impressão.</p>
			<div class="site-space-1"></div>
			<div class="block width-50">
				<button class="b b-padrao width-100" onclick="imovelImprimirTipo(${imo_codigo}, 1, 1, 1);"><i class="icl ic-file-image"></i> Retrato Simpless</button>
			</div><div class="block width-50">
				<button class="b b-padrao width-100" onclick="imovelImprimirTipo(${imo_codigo}, 2, 1, 2);"><i class="icl ic-file-image"></i> Retrato Completa</button>
			</div><div class="block width-50 align-middle">
				<button class="b b-padrao width-100" onclick="imovelImprimirTipo(${imo_codigo}, 1, 2, 1);"><i class="icl ic-image"></i> Paisagem Simples</button>
			<div class="site-space-1"></div>
		</div>
	`;

	Boss.dialog.open({
		'invisible': false,
		'close': true,
		'html': html
	});
}


/* PC */
window.toggleMenu = function(){

var nav = Boss.getById('nav-menu');

/* Se houver o elemento NAV, check de o menu está fechado e ABRE, se tiver aberto FECHA */
nav ? nav.classList.contains('nav_open') ? closeMenu() : openMenu() : false;
};

window.openMenu = function(){

var nav = Boss.getById('nav-menu');

/* PARA VERSÃO PC */
if(nav){
	nav.classList.add('nav_open');
	nav.classList.remove('nav_close');

	document.querySelector('.img-btn-menu') ? document.querySelector('.img-btn-menu').setAttribute('class', 'img-btn-menu icl ic-times') : false;
}
};

window.closeMenu = function(){
var nav = Boss.getById('nav-menu');

if(nav && nav.classList.contains('nav_open') === true){
	nav.classList.add('nav_close');
	nav.classList.remove('nav_open');
	document.querySelector('.img-btn-menu') ? document.querySelector('.img-btn-menu').setAttribute('class', 'img-btn-menu icl ic-bars') : false;
}
};

Boss.evts.add('scroll', document, evts => {

	if(document.querySelector('.b-tohome')){

		if(window.scrollY > (document.documentElement.clientHeight || 0)){
			document.querySelector('.b-tohome').classList.remove('hidden');
		}else{
			document.querySelector('.b-tohome').classList.add('hidden');
		}
	}
});