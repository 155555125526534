window.Whatsapp = {
	objeto: {},
	mensagem: 'gostaria de mais informações sobre os imóveis',
	css: `
	.container-fixo {
		display: block;
		width: 80px;
		position: fixed;
		bottom: 5px;
		right: 20px;
		z-index: 90;
		text-align: right;
	}
	.container-fixo .co-fixo-container {
		display: block;
		width: 100%;
		position: relative;
	}
	.container-fixo .co-fixo-whats {
		display: inline-block;
		width: 250px;
		vertical-align: middle;
		background-color: transparent;
		position: absolute;
		left: -168px;
		top: -18px;
	}
	.container-fixo .co-fixo-whats .box-img {
		display: block;
		position: absolute;
		top: -4px;
		left: -29px;
	}
	.container-fixo .co-fixo-whats .box-img img {
		border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-webkit-border-radius: 50%;
		-o-border-radius: 50%;
		display: block;
		width: 55px;
		height: 55px;
		-webkit-box-shadow: -2px 6px 7px -2px #00000026;
		-moz-box-shadow: -2px 6px 7px -2px #00000026;
		box-shadow: -2px 6px 7px -2px #00000026;
	}
	.container-fixo .co-fixo-whats .box-descricao {
		display: inline-block;
		width: 100%;
		text-align: left;
		font-size: 16px;
		padding: 6px 0;
		padding-left: 35px;
		font-weight: 300;
		color: #2f2f2f;
		border-radius: 0 0 0 0;
		-moz-border-radius: 0 0 0 0;
		-ms-border-radius: 0 0 0 0;
		-webkit-border-radius: 0 0 0 0;
		-o-border-radius: 0 0 0 0;
		background-color: #fff;
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		-ms-box-sizing: border-box;
		-o-box-sizing: border-box;
		vertical-align: middle;
		border-top: 1px solid #c9c9c9;
		border-bottom: 1px solid #c9c9c9;
		-webkit-box-shadow: -2px 6px 7px -2px #00000026;
		-moz-box-shadow: -2px 6px 7px -2px #00000026;
		box-shadow: -2px 6px 7px -2px #00000026;
	}
	.container-fixo .co-fixo-whats .box-descricao-a {
		border-radius: 0 30px 0 0;
		-moz-border-radius: 0 30px 0 0;
		-ms-border-radius: 0 30px 0 0;
		-webkit-border-radius: 0 30px 0 0;
		-o-border-radius: 0 30px 0 0;
	}
	.container-fixo .co-fixo-whats .box-descricao-b {
		border-radius: 0 0 30px 0;
		-moz-border-radius: 0 0 30px 0;
		-ms-border-radius: 0 0 30px 0;
		-webkit-border-radius: 0 0 30px 0;
		-o-border-radius: 0 0 30px 0;
	}
	.container-fixo .co-fixo-whats-btn {
		display: inline-block;
		margin-left: -70px;
		vertical-align: middle;
		position: relative;
	}
	.co_fixo_whats_open {
		-moz-animation-duration: 0.1s;
		-webkit-animation-duration: 0.1s;
		animation-duration: 0.1s;
		-moz-animation-name: co_fixo_whats_open;
		-webkit-animation-name: co_fixo_whats_open;
		animation-name: co_fixo_whats_open;
		-moz-animation-iteration-count: 1;
		-webkit-animation-iteration-count: 1;
		animation-iteration-count: 1;
		-moz-animation-direction: normal;
		-webkit-animation-direction: normal;
		animation-direction: normal;
		animation-fill-mode: forwards;
		animation-timing-function: ease-in-out;
	}
	@-webkit-keyframes co_fixo_whats_open {
		from {
			left: 10px;
			color: #2f2f2f;
		}
		to {
			color: transparent;
			left: 120vw;
		}
	}
	@-moz-keyframes co_fixo_whats_open {
		from {
			left: 10px;
			color: #2f2f2f;
		}
		to {
			color: transparent;
			left: 120vw;
		}
	}
	@-o-keyframes co_fixo_whats_open {
		from {
			left: 10px;
			color: #2f2f2f;
		}
		to {
			color: transparent;
			left: 120vw;
		}
	}
	@keyframes co_fixo_whats_open {
		from {
			left: 10px;
			color: #2f2f2f;
		}
		to {
			color: transparent;
			left: 120vw;
		}
	}
	.b-whats,
	.b-whats:hover,
	.b-whats:active,
	.b-whats:focus {
		background-color: #1ebea5;
		box-shadow: none;
		font-size: 20px !important;
		color: white !important;
		border: 0 !important;
		border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-webkit-border-radius: 50%;
		-o-border-radius: 50%;
		height: 70px !important;
		width: 70px !important;
		line-height: 78px !important;
		text-align: center !important;
		-webkit-box-shadow: -2px 6px 7px -2px #00000026;
		-moz-box-shadow: -2px 6px 7px -2px #00000026;
		box-shadow: -2px 6px 7px -2px #00000026;
	}
	.b-whats i,
	.b-whats:hover i,
	.b-whats:active i,
	.b-whats:focus i {
		font-size: 28px !important;
	}
	
	`,
	mascara: `
	<div class="co-fixo-whats co_fixo_whats_open">
		{{whatsapps}}
	</div>
	<div class="co-fixo-whats-btn">
		<button class="b b-whats" type="button" onclick="Whatsapp.open(this);">
			<i class="icb ic-whatsapp"></i>
		</button>
		<div id="ux_to_top" class="inline-block ux_to_top hidden" title="Voltar ao início"><span class="ic ic-chevron-up"></span></div>
	</div>
	`,
	mascara_whats: `
		<div class="co-fixo-container">
			<div class="cursor-pointer no-mobile" onclick="Whatsapp.go('{{numero_whatsapp}}')">
				<div class="box-img">
					<img src="{{src}}" alt="{{nome}}" />
				</div>
				<div class="box-descricao box-descricao-{{tipo}}">
					{{label}}. <br /><span class="font13 strong gray2">abrir whatsapp</span>
				</div>
			</div>
			<div class="cursor-pointer no-pc" onclick="Whatsapp.go('{{numero_whatsapp}}', 'mobile')">
				<div class="box-img">
					<img src="{{src}}" alt="{{nome}}" />
				</div>
				<div class="box-descricao box-descricao-{{tipo}}">
					{{label}}. <br /><span class="font13 strong gray2">abrir whatsapp</span>
				</div>
			</div>
		</div>`,


	_close: () => {
		var container = document.querySelector('.co-fixo-whats') ? document.querySelector('.co-fixo-whats') : false;
		var botao = document.querySelector('.b-whats') ? document.querySelector('.b-whats') : false;

		botao.classList.remove('b-whts-ativo');
		container.classList.add('co_fixo_whats_open');
		container.classList.remove('co_fixo_whats_close');
	},
	_open: () => {
		var container = document.querySelector('.co-fixo-whats') ? document.querySelector('.co-fixo-whats') : false;
		var botao = document.querySelector('.b-whats') ? document.querySelector('.b-whats') : false;

		botao.classList.add('b-whts-ativo');
		container.classList.remove('co_fixo_whats_open');
		container.classList.add('co_fixo_whats_close');
	},
	open: btn => {
		var container = document.querySelector('.co-fixo-whats') ? document.querySelector('.co-fixo-whats') : false;
		
		container.classList.contains('co_fixo_whats_open') ? Whatsapp._open() : Whatsapp._close();
	},
	go: (numero, dispositivo) => {

		/*var date = new Date;
		var hora = date.getHours();

		var texto = 'Boa noite, ' + this.mensagem;

		if(hora >= 6 && hora <= 12){
			texto = 'Bom dia, ' + this.mensagem;
		}

		if(hora >= 13 && hora <= 18){
			texto = 'Boa tarde, ' + this.mensagem;
		}*/

		var texto = '';
		dispositivo == 'mobile' ? window.open("https://api.whatsapp.com/send?phone=" + numero + "&text=" + texto, '_blank') : window.open("https://web.whatsapp.com/send?phone="+numero+"&text=" + texto, '_blank');
	},
	replace: (string, procurar, substituir) => {

		let i,
			regex 	= [],
			map 	= {};

		for(i = 0; i < procurar.length; i++){

			regex.push( procurar[i].replace(/([-[\]{}()*+?.\\^$|#,])/g,'\\$1'));
			map[procurar[i]] = substituir[i];
		}

		regex = regex.join('|');

		string = string.replace( new RegExp( regex, 'g' ), function(matched){
			return map[matched];
		});

		return string;
	},
	isDicionario: dicionario => {
		return typeof dicionario === 'object' && dicionario !== null && !(dicionario instanceof Array) && !(dicionario instanceof Date);
	},
	render: (objeto = {}, mensagem = '', mascara = '', sem_flag = false) => {

		/* Se não passar o objeto com números e nomes, não renderiza a parada */
		if(!Whatsapp.isDicionario(objeto)){
			return false;
		}

		var head = document.getElementsByTagName('head')[0];

		var style = document.createElement('style');
		style.type = 'text/css';
		head.appendChild(style);
		
		if(style.styleSheet){
			style.styleSheet.cssText = Whatsapp.css;
		}else{
			style.appendChild(document.createTextNode(Whatsapp.css));
		}

		let whatsapps 	= '';
		let find 		= [];
		let replace 	= [];
		
		var indices = 0;
		for(var i in objeto){
			
			if(sem_flag == true){
				continue;
			}
			if(indices > 1){
				continue;
			}
			indices = indices + 1;

			find = ["{{nome}}", "{{label}}", "{{numero_whatsapp}}", "{{src}}", "{{tipo}}"];
			replace = [objeto[i]['nome'], objeto[i]['label'], objeto[i]['numero_whatsapp'], objeto[i]['src'], (objeto[i]['tipo'] == '1') ? 'a' : 'b'];

			whatsapps += Whatsapp.replace(Whatsapp.mascara_whats, find, replace);
		}

		let html = '';
		find = ["{{whatsapps}}"];
		replace = [whatsapps];

		html = Whatsapp.replace(Whatsapp.mascara, find, replace);

		container_fixo.innerHTML = html;
	}
}